import { ReactComponent as CloseIcon } from "assets/icons/24x24/24x24_close.svg";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { PopUpLayout } from "render/ui/layout/PopUpLayout/PopUpLayout";
import { Typography } from "render/ui/presentation/Typography";
import { Button } from "render/ui/trigger/Button";
import { IconButton } from "render/ui/trigger/IconButton";
import { Trans, contactUsNumber } from "../ContactUs/trans";
import styles from "./styles.module.sass";

interface ContactUsPopupProps {
  onClose: () => void;
}

export function ContactUsPopup({ onClose }: ContactUsPopupProps) {
  const patientQuery = usePatientQuery();

  if (!patientQuery.data) {
    return null;
  }

  const countryCode = patientQuery.data.preferredCountry;

  return (
    <PopUpLayout>
      <div className={styles.ContactUsPopup}>
        <div className={styles.header}>
          <Typography variant="title-m">
            <Trans.ContactUs.Title />
          </Typography>
          <span className={styles.close}>
            <IconButton
              onClick={onClose}
              icon={<CloseIcon />}
              ariaLabel={Trans.ContactUs.Close()}
            />
          </span>
        </div>
        <div className={styles.body}>
          <div className={styles.content}>
            <div className={styles.info}>
              <Typography variant="body-m" color="subtle">
                <Trans.ContactUs.Text countryCode={countryCode} />{" "}
              </Typography>
              <Typography variant="body-m" color="subtle">
                <Trans.ContactUs.ChestPain countryCode={countryCode} />
              </Typography>
            </div>

            <div className={styles.cta}>
              <a href={`sms:${contactUsNumber(countryCode)}`}>
                <Button variant="outlined">
                  <Typography variant="body-label-m">
                    <Trans.ContactUs.TextUs />
                  </Typography>
                </Button>
              </a>
              <div className={styles.close}>
                <Button onClick={onClose} variant="contained">
                  <Typography variant="body-label-m">
                    <Trans.ContactUs.Close />
                  </Typography>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PopUpLayout>
  );
}
