import { sha256 } from "sha.js";
import { Patient } from "@cur8/rich-entity";

export class GoogleTagManager {
  private setHasLoaded: (
    value: ((prevState: boolean) => boolean) | boolean
  ) => void;

  constructor(
    setHasLoaded: (value: ((prevState: boolean) => boolean) | boolean) => void
  ) {
    this.setHasLoaded = setHasLoaded;
  }
  event = (eventName: string, eventParams: any) => {
    gtag("event", eventName, eventParams);
  };

  getHash = (string: string) => {
    return new sha256().update(string).digest("hex");
  };

  GiveConsent = () => {
    this.event("GiveConsent", {});
  };

  Init = (patient: Patient) => {
    this.externalUserId = this.getHash(patient.patientId);
    this.externalEmails = [this.getHash(patient.contactDetails.email)];
    this.externalPhoneNumbers = [];
    for (const phoneNumber of patient.contactDetails.phoneNumbers) {
      this.externalPhoneNumbers.push(this.getHash(phoneNumber));
    }
    gtag("set", "user_data", {
      external_user_id: this.externalUserId,
      email_address:
        this.externalEmails?.length === 1
          ? this.externalEmails?.at(0)
          : this.externalEmails,
      phone_number:
        this.externalPhoneNumbers?.length === 1
          ? this.externalPhoneNumbers?.at(0)
          : this.externalPhoneNumbers,
    });
    this.setHasLoaded(true);
  };
  private externalUserId: string | undefined;
  private externalEmails: string[] | undefined;
  private externalPhoneNumbers: any[] | undefined;
}
