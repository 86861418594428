import { GoogleTagManager } from "lib/googleTagManager";
import { Cart } from "render/hooks/mutations/useCreateCartMutation";
import { sha256 } from "sha.js";

const DEFAULT_ITEM_PRICE = 2500;

export class ConversionsTracking {
  gtm: GoogleTagManager;

  constructor(gtm: GoogleTagManager) {
    this.gtm = gtm;
  }

  getFromCart = (cart: Cart) => {
    let discount = 0;
    const itemPrice = cart.entries.at(0)?.product?.price || DEFAULT_ITEM_PRICE;

    if (cart.discountCodes.length > 0) {
      discount =
        cart.cartTotal > 10 ? itemPrice - cart.cartTotal : cart.cartTotal;
    }

    const total = cart.cartTotal > 10 ? cart.cartTotal : 0;

    return {
      currency: cart.currency,
      value: total,
      coupon: cart.discountCodes.at(0),
      discount: discount,
      items: cart.entries.map((entry) => ({
        item_id: entry.product?.id,
        item_name: entry.product?.name,
        price: entry.product?.price,
        quantity: 1,
      })),
    };
  };

  TrackAddToCart = (cart: Cart) => {
    this.gtm.event("add_to_cart", this.getFromCart(cart));
  };

  TrackAddPaymentInfo = (cart: Cart, paymentType: string) => {
    const total = cart.cartTotal > 10 ? cart.cartTotal : 0;

    this.gtm.event("add_payment_info", {
      currency: cart.currency,
      value: total,
      coupon: cart.discountCodes.at(0),
      payment_type: paymentType,
      items: cart.entries.map((entry) => ({
        item_id: entry.product?.id,
        item_name: entry.product?.name,
        price: entry.product?.price,
        quantity: 1,
      })),
    });
  };

  TrackBeginCheckout = (cart: Cart) => {
    this.gtm.event("begin_checkout", this.getFromCart(cart));
  };

  TrackPurchase = (cart: Cart) => {
    const seed =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    const total = cart.cartTotal > 10 ? cart.cartTotal : 0;

    this.gtm.event("purchase", {
      currency: cart.currency,
      // TODO: we should be using a Cart/Order ID to hash instead of something pseudo-random
      transaction_id: new sha256().update(seed).digest("hex"),
      value: total,
      coupon: cart.discountCodes.at(0),
      items: cart.entries.map((entry) => ({
        item_id: entry.product?.id,
        item_name: entry.product?.name,
        price: entry.product?.price,
        quantity: 1,
      })),
    });
  };

  TrackWalletSupported = (wallet: string) => {
    this.gtm.event("wallet_payment_supported", {
      type: wallet,
    });
  };

  TrackAddDiscountCode(code: string) {
    this.gtm.event("add_discount_code", {
      coupon: code,
    });
  }

  TrackAddInviteCode(code: string) {
    this.gtm.event("add_invite_code", {
      coupon: code,
    });
  }

  TrackRemoveDiscountCode(code: string) {
    this.gtm.event("remove_discount_code", {
      coupon: code,
    });
  }

  TrackLogin() {
    this.gtm.event("login", {
      method: "BankID",
    });
  }

  TrackCopyInviteCode(code: string) {
    this.gtm.event("copy_invite_code", {
      coupon: code,
    });
  }
}
