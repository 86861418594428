import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { ReactComponent as ChevronRightIcon } from "assets/icons/16x16/16x16_chevron-right.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/24x24/24x24_arrow_left.svg";
import { checkIfSigned } from "lib/consents/checkIfSigned";
import { useLocalisedMetadataDocumentUri } from "lib/consents/documentUri";
import { mapConsents, MappedConsent } from "lib/consents/mapConsents";
import { parseDocument } from "lib/legal/parseDocument";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLegalDocumentQuery } from "render/hooks/api/queries/useLegalDocumentQuery";
import { useLatestConsentsByType } from "render/hooks/api/queries/usePatientConsentsQuery";
import { usePreVisitConsentStudiesForVisitId } from "render/hooks/api/queries/useVisitQuery";
import { useGiveConsentMutation } from "render/hooks/mutations/consent/useGiveConsentMutation";
import { useRevokeConsentMutation } from "render/hooks/mutations/consent/useRevokeConsentMutation";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { Checkbox } from "render/ui/form/Checkbox";
import { Backdrop } from "render/ui/layout/Backdrop";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Skeleton } from "render/ui/presentation/Skeleton";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import { ConsentDocument } from "render/views/ProfileView/components/ConsentDocument";
import { SharedTrans } from "render/views/trans";
import { Trans } from "../../trans";
import styles from "./styles.module.sass";

interface OnboardingStudiesConsentViewProps {
  onSave: () => void;
  onClose?: () => void;
  visitId: string;
}

type HandleCheckProps = {
  consentId: string;
  id: string | undefined;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  value: boolean;
  documentUri: string;
};

export function OnboardingStudiesConsentView({
  onSave,
  onClose,
  visitId,
}: OnboardingStudiesConsentViewProps) {
  const { data: studiesOnVisit } = usePreVisitConsentStudiesForVisitId(visitId);

  const { enqueueSnackbar } = useSnackbar();
  const { data: patientConsents } = useLatestConsentsByType();

  const [selectedStudyId, setSelectedStudyId] = useState<string>();
  const { mutate: giveStudyConsentMutation } = useGiveConsentMutation();
  const { mutate: revokeStudyConsentMutation } = useRevokeConsentMutation();

  const toggleStudyConsent = useAsyncHandle(
    async ({
      consentId,
      id,
      value,
      documentUri,
    }: Omit<HandleCheckProps, "setIsChecked">) => {
      try {
        if (value) {
          giveStudyConsentMutation({
            documentUri,
            consentMetadataId: consentId,
          });
        } else {
          revokeStudyConsentMutation({ consentId: id });
        }
      } catch (e) {
        enqueueSnackbar(<Trans.Error.FailedToGiveConsent />, {
          variant: "error",
        });
        throw e;
      }
    }
  );

  const toggleHandler = useCallback(
    async ({
      consentId,
      id,
      setIsChecked,
      value,
      documentUri,
    }: HandleCheckProps) => {
      try {
        setIsChecked((prev) => !prev);
        await toggleStudyConsent.callback({
          consentId,
          id,
          value,
          documentUri,
        });
      } catch (e) {
        setIsChecked((prev) => !prev);
      }
    },
    [toggleStudyConsent]
  );

  const handleCheck = useAsyncHandle(toggleHandler);

  const mappedConsents = mapConsents({
    requiredConsents: studiesOnVisit,
    previouslySignedConsents: patientConsents?.studies,
  });

  const selectedStudy = useMemo(() => {
    const selectedStudy = mappedConsents?.find(
      (c) => c.consentMetadata?.consentId === selectedStudyId
    );
    if (!selectedStudy) {
      return undefined;
    }
    return {
      consentMetadata: selectedStudy.consentMetadata,
      relevantSignature: selectedStudy.relevantSignature,
    };
  }, [mappedConsents, selectedStudyId]);

  const hasAgreedToAllStudies = useMemo(() => {
    const numberOfSignedStudies = mappedConsents?.filter((consent) =>
      checkIfSigned(consent?.relevantSignature)
    ).length;
    const numberOfStudies = studiesOnVisit?.length;

    return numberOfSignedStudies === numberOfStudies;
  }, [mappedConsents, studiesOnVisit?.length]);

  const handleSubmit = async () => {
    onSave();
  };

  const onCloseStudyDetail = () => {
    setSelectedStudyId(undefined);
  };
  const closeAriaLabel = SharedTrans.Close();

  if (patientConsents == null || studiesOnVisit == null) {
    return undefined;
  }

  return (
    <ViewStack>
      <FullScreenPageLayout>
        <div className={styles.sticky}>
          <LogoHeader
            leftElement={
              onClose && (
                <IconButton
                  ariaLabel={closeAriaLabel}
                  onClick={onClose}
                  icon={<ArrowLeftIcon />}
                />
              )
            }
          />
        </div>

        <div className={styles.body}>
          <Typography variant="display-s">
            <Trans.StudyConsent.Title />
          </Typography>
          <Typography variant="body-m" color="subtle">
            <Trans.StudyConsent.Subtitle />
          </Typography>

          <ul className={styles.studies}>
            {mappedConsents
              ?.toSorted((a, b) =>
                a.consentMetadata.consentId.localeCompare(
                  b.consentMetadata.consentId
                )
              )
              .map((study) => (
                <li
                  key={study.consentMetadata?.consentId}
                  className={styles.study}
                >
                  <Study
                    consent={study}
                    handleCheck={handleCheck}
                    setSelectedStudyId={setSelectedStudyId}
                  />
                </li>
              ))}
          </ul>

          <Typography variant="body-xs" color="subtle">
            <Trans.StudyConsent.Footnote />
          </Typography>

          {hasAgreedToAllStudies && (
            <div className={styles.footer}>
              <ActionButton variant="suggestion" onClick={handleSubmit}>
                <Trans.StudyConsent.AgreeAndSign />
              </ActionButton>
            </div>
          )}
        </div>
      </FullScreenPageLayout>
      <Backdrop
        active={selectedStudyId != null}
        kind="subtle"
        onOutsideClick={onCloseStudyDetail}
      >
        <Sticky>
          {selectedStudy && (
            <ConsentDocument
              key={selectedStudyId}
              onClose={onCloseStudyDetail}
              consent={selectedStudy}
            />
          )}
        </Sticky>
      </Backdrop>
    </ViewStack>
  );
}

type StudyProps = {
  consent: MappedConsent;
  setSelectedStudyId: React.Dispatch<React.SetStateAction<string | undefined>>;
  handleCheck: {
    busy: boolean;
    callback: ({
      consentId,
      id,
      setIsChecked,
      value,
      documentUri,
    }: HandleCheckProps) => Promise<void> | undefined;
  };
};

function Study({ consent, setSelectedStudyId, handleCheck }: StudyProps) {
  const documentUri = useLocalisedMetadataDocumentUri(consent);
  const { data: legalDoc } = useLegalDocumentQuery(documentUri);

  const isSigned = useMemo(() => {
    return checkIfSigned(consent?.relevantSignature);
  }, [consent?.relevantSignature]);

  const [isChecked, setIsChecked] = useState(isSigned);

  // required to keep the checkbox in sync with any changes made on the ConsentDocument page
  useEffect(() => {
    setIsChecked(isSigned);
  }, [consent.relevantSignature, isSigned]);

  const consentId = consent.consentMetadata?.consentId;
  const id = consent.relevantSignature?.id;

  if (legalDoc == null || consentId == null || documentUri == null) {
    return (
      <div className={styles.skeletonWrapper}>
        <Skeleton />
      </div>
    );
  }

  const { title, description } = parseDocument(legalDoc);

  return (
    <>
      <div className={styles.card}>
        <div className={styles.cardTitle}>
          <Typography variant="body-label-m">{title}</Typography>
          <button
            className={styles.cardLink}
            onClick={() => {
              setSelectedStudyId(consent.consentMetadata?.consentId);
            }}
          >
            <Typography variant="body-s" color="subtle">
              <Trans.StudyConsent.LearnMore />
            </Typography>
            <ChevronRightIcon />
          </button>
        </div>
        <Typography variant="body-s" color="subtle">
          {description}
        </Typography>
      </div>
      <Checkbox
        checked={isChecked}
        name={consentId}
        onChange={(event) => {
          const value = event.currentTarget.checked;
          if (handleCheck.busy) {
            return;
          }
          handleCheck.callback({
            consentId,
            id,
            documentUri,
            setIsChecked,
            value,
          });
        }}
      >
        <Typography variant="body-m" color="subtle">
          <Trans.StudyConsent.Participate />
        </Typography>
      </Checkbox>
    </>
  );
}
