import { Choice } from "@cur8/questionnaire";
import { ReactComponent as ArrowSvg } from "assets/icons/24x24/24x24_arrow-right.svg";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

type RadioProps<T> = {
  choiceIndex: number;
  pickedIndex: number;
  choiceDescription?: React.ReactNode;
  choice: Choice<T, React.ReactNode>;
  handleChoice: (choiceNumber: number) => void;
};

export function Radio<T>({
  choiceIndex,
  pickedIndex,
  choiceDescription,
  choice,
  handleChoice,
}: RadioProps<T>) {
  return (
    <label
      className={styles.choice}
      key={choiceIndex}
      data-picked={choiceIndex === pickedIndex}
    >
      <input
        type="radio"
        onClick={() => handleChoice(choiceIndex)}
        id={String(choiceIndex)}
        value={choiceIndex}
      />
      <div className={styles.content}>
        {choiceDescription ? (
          <div>
            <Typography variant="body-label-m">{choice.statement}</Typography>
            <Typography variant="body-s" color="subtle">
              {choiceDescription}
            </Typography>
          </div>
        ) : (
          <Typography variant="body-label-m">{choice.statement}</Typography>
        )}
        <div className={styles.icon}>
          <ArrowSvg aria-hidden={true} />
        </div>
      </div>
    </label>
  );
}
