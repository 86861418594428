import { Typography } from "render/ui/presentation/Typography";
import { Card } from "render/ui/trigger/Card";
import styles from "./styles.module.sass";
import * as Trans from "./Trans";

type CreatingProps = {
  email?: string;
  requestedOn: string;
};

export function Creating({ email, requestedOn }: CreatingProps) {
  return (
    <Card>
      <div className={styles.text}>
        <Typography variant="title-s">
          <Trans.CreatingFiles />
        </Typography>
        <Typography variant="body-m" color="subtle">
          <Trans.EmailNotification email={email} />
        </Typography>
      </div>
      <Typography variant="eyebrow-m" color="subtle">
        <Trans.RequestedOn date={requestedOn} />
      </Typography>
    </Card>
  );
}
