import { createContext, useContext, useMemo, useState } from "react";
import { GoogleTagManager } from "lib/googleTagManager";
import { ConversionsTracking } from "lib/conversionsTracking";

type Tracking = {
  gtm: GoogleTagManager;
  conversionsTracking: ConversionsTracking;
  hasLoaded: boolean;
};

const Context = createContext<Tracking | null>(null);

interface TrackingContextProps {
  children: React.ReactNode;
}

export function TrackingContext({ children }: TrackingContextProps) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [gtm] = useState(new GoogleTagManager(setHasLoaded));

  const value = useMemo(() => {
    return {
      gtm: gtm,
      conversionsTracking: new ConversionsTracking(gtm),
      hasLoaded: hasLoaded,
    };
  }, [gtm, hasLoaded]);
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useTrackingContext() {
  const trackingContext = useContext(Context);
  if (!trackingContext) {
    throw new Error("useTrackingContext without TrackingContext");
  }
  return trackingContext;
}
