import { ReactComponent as CheckIconSmall } from "assets/icons/16x16/16x16_checkmark.svg";
import { ReactComponent as ChevronIconSmall } from "assets/icons/16x16/16x16_chevron-right.svg";
import React from "react";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

interface StepButtonProps extends React.ButtonHTMLAttributes<unknown> {
  completed: boolean;
}

export function StepButton({
  completed,
  children,
  disabled,
  ...props
}: StepButtonProps) {
  return (
    <button
      className={styles.StepButton}
      disabled={disabled}
      data-completed={completed}
      data-can-click={props.onClick != null}
      {...props}
    >
      <div className={styles.iconContainer}>
        <div className={styles.circle}>
          <CheckIconSmall />
        </div>
      </div>
      <div className={styles.todoText}>
        <Typography color={disabled ? "subtle" : "default"} variant="body-m">
          {children}
        </Typography>
      </div>
      {props.onClick && <ChevronIconSmall className={styles.chevron} />}
    </button>
  );
}
