import { ReactComponent as PlusIcon } from "assets/icons/24x24/24x24_plus.svg";
import { useState } from "react";
import { Expandable } from "render/ui/presentation/Expandable";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

interface AccordionProps {
  header: JSX.Element | string;
  text: JSX.Element | string;
}

export function Accordion({ header, text }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.Accordion}>
      <div className={styles.header} onClick={() => setIsOpen((v) => !v)}>
        <Typography variant="body-m">{header}</Typography>
        <PlusIcon className={styles.icon} data-open={isOpen} />
      </div>
      <Expandable isOpen={isOpen}>
        <div className={styles.content}>{text}</div>
      </Expandable>
    </div>
  );
}
