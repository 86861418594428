import { useNav, useQueryParams } from "@pomle/react-router-paths";
import { ReactComponent as QuestionIcon } from "assets/icons/24x24/24x24_chat.svg";
import { ReactComponent as CrossIcon } from "assets/icons/24x24/24x24_close.svg";
import { useCallback, useEffect, useMemo } from "react";
import { useMSAL } from "render/context/MSALContext";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { paths, queries } from "render/routes/paths";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";
import { splitSlotId } from "lib/visit/splitSlotId";
import { useVisitPrerequisites } from "render/hooks/api/useVisitPrerequisites";

interface PasskeyResultViewProps {
  slotId: string;
}

export function PasskeyResultView({ slotId }: PasskeyResultViewProps) {
  const contactUsPopup = useContactUsPopup();
  const { stepUpAccess, session } = useMSAL();

  const { visitId } = splitSlotId(slotId);
  const [params] = useQueryParams(queries.appointmentPrereq);
  const visitPrerequisites = useVisitPrerequisites({
    origin: params.origin[0],
    visitId,
  });

  const nav = {
    appointment: useNav(paths.appointment),
    prereq: useNav(paths.appointmentPrereq.root, queries.appointmentPrereq),
  };

  const succeeded = useMemo(
    () => session?.authenticationMethods.includes("passkey"),
    [session]
  );

  useEffect(() => {
    if (!visitPrerequisites.hasLoaded) {
      return;
    }
    if (!succeeded) {
      return;
    }

    const next = visitPrerequisites.getNextStep({ after: "passkey" });
    if (next) {
      next(slotId);
      return;
    }
    if (params.origin[0] === "prereq") {
      nav.prereq.go({ slotId }, { origin: params.origin });
    } else {
      nav.appointment.go({
        slotId,
      });
    }
  }, [
    succeeded,
    nav.appointment,
    slotId,
    params.origin,
    nav.prereq,
    visitPrerequisites.hasLoaded,
    visitPrerequisites,
  ]);

  const handleTryAgain = useCallback(() => {
    stepUpAccess("psk");
  }, [stepUpAccess]);

  const onClose = useCallback(() => {
    if (params.origin[0] === "prereq") {
      nav.prereq.go({ slotId });
    } else {
      nav.appointment.go({ slotId });
    }
  }, [nav.appointment, nav.prereq, params.origin, slotId]);

  if (succeeded !== false) {
    return null;
  }

  return (
    <FullScreenPageLayout>
      <BurgerLayout>
        <LogoHeader
          leftElement={<IconButton onClick={onClose} icon={<CrossIcon />} />}
          rightElement={
            <IconButton
              icon={<QuestionIcon display="block" />}
              onClick={contactUsPopup.emit}
            />
          }
        />
        <div className={styles.body}>
          <div className={styles.content}>
            <Typography variant="display-s">
              <Trans.Title />
            </Typography>
            <Typography style={{ marginTop: "12px" }} variant="body-m">
              <Trans.Paragraph1 />
            </Typography>
          </div>
        </div>
        <div className={styles.cta}>
          <div className={styles.action}>
            <ActionButton onClick={handleTryAgain} variant="suggestion">
              <Trans.TryAgain />
            </ActionButton>
          </div>
        </div>
      </BurgerLayout>
    </FullScreenPageLayout>
  );
}
