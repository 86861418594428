import {
  AccountInfo,
  AuthenticationResult,
  EventType,
  IPublicClientApplication,
  NavigationClient,
  NavigationOptions,
} from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useRouter } from "@pomle/react-router-paths";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useConfig } from "../ConfigContext";
import { AuthenticationMethod, Session, UseMSALReturnProps } from "./types";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { isError } from "lib/error/isError";
export { useMsalAuthentication } from "@azure/msal-react";

type Claims = {
  extension_PatientId?: string;
  newuser?: string;
  amr?: AuthenticationMethod[];
  step_up_unmet?: string;
};

class CustomNavigationClient extends NavigationClient {
  private appInsights: ApplicationInsights;
  private history: ReturnType<typeof useRouter>["history"];

  constructor({
    appInsights,
    history,
  }: {
    history: ReturnType<typeof useRouter>["history"];
    appInsights: ApplicationInsights;
  }) {
    super();
    this.history = history;
    this.appInsights = appInsights;
  }

  async navigateInternal(
    url: string,
    options: NavigationOptions
  ): Promise<boolean> {
    try {
      if (options.noHistory) {
        this.history.replace(url);
        this.appInsights.trackEvent({
          name: "navigation-client",
          properties: { target: url, variant: "replace" },
        });
      } else {
        this.history.push(url);
        this.appInsights.trackEvent({
          name: "navigation-client",
          properties: { target: url, variant: "push" },
        });
      }
      return false;
    } catch (e) {
      this.appInsights.trackException({
        exception: isError(e)
          ? e
          : new Error("Navigation client navigate internal fail"),
        severityLevel: SeverityLevel.Error,
      });
      throw e;
    }
  }
}

function createSession(account: AccountInfo): Session {
  const claims = account.idTokenClaims as Claims;
  const patientId = claims.extension_PatientId;

  // HACK: Majority of the code base assumes patientId is always present,
  //       so let's throw an error so we have explicit types.
  if (!patientId) {
    throw new Error("Patient ID not found in claims");
  }

  return {
    patientId: patientId,
    isNewUser: claims.newuser === "True",
    authenticationMethods: claims.amr ?? [],
    stepUpUnmet: claims.step_up_unmet === "True",
    mfa: claims.amr?.includes("mfa") ?? false,
  };
}

export function MSALContext({
  children,
  msal,
}: PropsWithChildren<{ msal: IPublicClientApplication }>) {
  const appInsights = useAppInsights();
  const { history } = useRouter();

  const setAuthenticatedUserContext = useCallback(
    (account: AccountInfo | null) => {
      if (account) {
        const session = createSession(account);
        appInsights.setAuthenticatedUserContext(session.patientId);
      } else {
        appInsights.clearAuthenticatedUserContext();
      }
    },
    [appInsights]
  );

  const instance = useMemo(() => {
    const result = msal;
    msal.setNavigationClient(
      new CustomNavigationClient({ appInsights, history })
    );

    /**
     * Event list
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md
     */
    result.addEventCallback((message) => {
      const failureEvents = new Set<EventType>([EventType.LOGIN_FAILURE]);
      if (!failureEvents.has(message.eventType)) {
        return;
      }

      appInsights.trackException({
        error: message.error ?? undefined,
        exception: message.error ?? undefined,
        severityLevel: SeverityLevel.Error,
      });
    });
    result.addEventCallback((message) => {
      if (message.eventType !== EventType.LOGIN_SUCCESS) {
        return;
      }
      const payload = message.payload as AuthenticationResult;
      result.setActiveAccount(payload.account);
      setAuthenticatedUserContext(payload.account);
    });
    result.addEventCallback(({ eventType }) => {
      if (eventType !== EventType.INITIALIZE_START) {
        return;
      }
      const activeAccount = result.getActiveAccount();
      setAuthenticatedUserContext(activeAccount);
    });

    return result;
  }, [appInsights, msal, setAuthenticatedUserContext, history]);

  return <MsalProvider instance={instance}>{children}</MsalProvider>;
}

export function useMSAL(): UseMSALReturnProps {
  const { instance } = useMsal();
  const config = useConfig();

  const account = instance.getActiveAccount();

  const session = useMemo(() => {
    if (account) {
      return createSession(account);
    }
    return null;
  }, [account]);

  const auth = useMemo(() => (account ? { account } : undefined), [account]);

  const stepUpAccess = useCallback(
    async (method: "psk" | "idv", redirectStartPage?: string) => {
      const account = instance.getActiveAccount();
      if (!account) {
        throw new Error("No active account");
      }

      return instance.acquireTokenRedirect({
        scopes: config.appConfig.msal.scopes.token,
        redirectUri: window.location.origin,
        extraQueryParameters: {
          acr_values: method,
        },
        redirectStartPage: redirectStartPage,
      });
    },
    [instance, config.appConfig.msal.scopes.token]
  );

  const logout = useCallback(
    async (props: Partial<{ postLogoutRedirectUrl: string }> = {}) => {
      const account = instance.getActiveAccount();
      instance.logoutRedirect({
        postLogoutRedirectUri: props.postLogoutRedirectUrl,
        idTokenHint: account?.idToken,
      });
    },
    [instance]
  );

  return {
    logout,
    stepUpAccess,
    instance,
    auth,
    session,
  };
}

export function useSession(): Session {
  const { session } = useMSAL();
  if (!session) {
    throw new Error("No Session account");
  }
  return session;
}
