import { ViewStack } from "@pomle/react-viewstack";
import { Direction, Slide } from "@pomle/react-viewstack-transitions";
import { ReactComponent as QuestionIcon } from "assets/icons/24x24/24x24_chat.svg";
import { useState } from "react";
import { useIsQuestionnaireCompleteQuery } from "render/hooks/api/queries/useQuestionnaireQuery";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import { OnboardingFlowView } from "render/views/OnboardingView/components/OnboardingFlowView";
import { AtriumOnboardingStudiesConsentView } from "render/views/partners/atrium/AtriumSignIn/components/AtriumOnboardingStudyConsentView";
import { SharedTrans } from "render/views/trans";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

enum ViewActiveState {
  Initial,
  OnboardingStudies,
  OnboardingQuestions,
}

interface AtriumOnboardingProps {
  onSuccess: () => void;
}

export function AtriumOnboarding({ onSuccess }: AtriumOnboardingProps) {
  const [activeStage, setActiveStage] = useState<ViewActiveState>(
    ViewActiveState.Initial
  );
  const contactUsPopup = useContactUsPopup();
  const onboardingQuery = useIsQuestionnaireCompleteQuery();

  return (
    <ViewStack>
      <Slide
        active={activeStage >= ViewActiveState.Initial}
        direction={Direction.Left}
      >
        <FullScreenPageLayout disableBackground>
          <BurgerLayout>
            <LogoHeader
              rightElement={
                <IconButton
                  ariaLabel={SharedTrans.ContactUs()}
                  icon={<QuestionIcon display="block" />}
                  onClick={() => {
                    contactUsPopup.emit();
                  }}
                />
              }
            />
            <div className={styles.body}>
              <div className={styles.content}>
                <Typography variant="display-s">
                  <Trans.Title />
                </Typography>
                <ActionButton
                  onClick={() => {
                    setActiveStage(ViewActiveState.OnboardingStudies);
                  }}
                >
                  <Trans.CompleteProfile />
                </ActionButton>
              </div>
            </div>
            <></>
          </BurgerLayout>
        </FullScreenPageLayout>
      </Slide>
      <Slide
        active={activeStage >= ViewActiveState.OnboardingStudies}
        direction={Direction.Right}
      >
        <AtriumOnboardingStudiesConsentView
          onSave={() => {
            if (onboardingQuery.data == null) {
              return setActiveStage(ViewActiveState.OnboardingQuestions);
            }
            onSuccess();
          }}
        />
      </Slide>
      <Slide
        active={activeStage >= ViewActiveState.OnboardingQuestions}
        direction={Direction.Right}
      >
        <OnboardingFlowView
          onSuccess={() => {
            onSuccess();
          }}
          onNavBack={() => {
            setActiveStage(ViewActiveState.OnboardingStudies);
          }}
          visitId={undefined}
        />
      </Slide>
    </ViewStack>
  );
}
