import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { ReactComponent as QuestionIcon } from "assets/icons/24x24/24x24_chat.svg";
import { ReactComponent as CrossIcon } from "assets/icons/24x24/24x24_close.svg";
import { checkIfSigned } from "lib/consents/checkIfSigned";
import { TransStudies } from "lib/i18n/translations/studies";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useLatestConsentsQuery } from "render/hooks/api/queries/usePatientConsentsQuery";
import { useGiveConsentMutation } from "render/hooks/mutations/consent/useGiveConsentMutation";
import { useRevokeConsentMutation } from "render/hooks/mutations/consent/useRevokeConsentMutation";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { useTracking } from "render/hooks/useTracking";
import { Backdrop } from "render/ui/layout/Backdrop";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Toggle } from "render/ui/presentation/Toggle";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import { onboardingEvent } from "render/views/OnboardingView/tracking";
import { ConsentDocument } from "render/views/ProfileView/components/ConsentDocument";
import {
  Study,
  useConsentsMap,
} from "render/views/ProfileView/components/DataAndPrivacySection/hooks/useConsentsMap";
import { SharedTrans } from "render/views/trans";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface OnboardingStudiesConsentViewProps {
  onSave: () => void;
  onClose?: () => void;
}

export function AtriumOnboardingStudiesConsentView({
  onSave,
  onClose,
}: OnboardingStudiesConsentViewProps) {
  const { trackEvent } = useTracking();
  const contactUsPopup = useContactUsPopup();
  const { enqueueSnackbar } = useSnackbar();
  const patientConsentsQuery = useLatestConsentsQuery();
  const { studies } = useConsentsMap();
  const [study, setStudy] = useState<Study>();
  const { mutate: giveStudyConsentMutation } = useGiveConsentMutation();
  const { mutate: revokeStudyConsentMutation } = useRevokeConsentMutation();
  const [toggleState, setToggleState] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setToggleState(
      Object.fromEntries(
        Object.values(studies).map((s) => [
          s.name,
          (patientConsentsQuery.data ?? []).some(
            (c) =>
              c.relevantSignature.documentUrl.includes(s.name) &&
              checkIfSigned(c.relevantSignature)
          ),
        ])
      )
    );
  }, [patientConsentsQuery.data, studies]);

  const selectedStudy =
    study &&
    patientConsentsQuery.data?.find((c) =>
      c.relevantSignature.documentUrl.includes(study.name)
    );

  const hasAgreedToAnyStudy = Object.values(toggleState).some(Boolean);

  function onCloseStudyDetail() {
    patientConsentsQuery.refetch();
    setStudy(undefined);
  }

  async function toggle(study: Study, newConsentValue: boolean) {
    setToggleState((s) => ({ ...s, [study.name]: newConsentValue }));
    const studyUrl = study.getUrl("en");
    try {
      if (newConsentValue) {
        return giveStudyConsentMutation({
          documentUri: studyUrl,
          consentMetadataId: undefined,
        });
      }
      const consent = patientConsentsQuery.data?.find(
        (c) => c.relevantSignature.documentUrl === studyUrl
      );
      if (!consent) {
        throw new Error("Consent not found");
      }
      revokeStudyConsentMutation({ consentId: consent.relevantSignature.id });
    } catch (e) {
      setToggleState((s) => ({ ...s, [study.name]: !newConsentValue }));
      enqueueSnackbar(<Trans.Error.FailedToGiveConsent />, {
        variant: "error",
      });
      throw e;
    }
  }

  return (
    <FullScreenPageLayout>
      <ViewStack>
        <BurgerLayout>
          <LogoHeader
            hideLogo
            leftElement={
              onClose && <IconButton onClick={onClose} icon={<CrossIcon />} />
            }
            rightElement={
              <IconButton
                ariaLabel={SharedTrans.ContactUs()}
                icon={<QuestionIcon display="block" />}
                onClick={() => {
                  trackEvent(onboardingEvent.openContactsSidebarClick("open"));
                  contactUsPopup.emit();
                }}
              />
            }
          />

          <div className={styles.body}>
            <div className={styles.content}>
              <Typography variant="display-s">
                <Trans.StudyConsent.Title />
              </Typography>
              <Typography style={{ marginTop: "16px" }} variant="body-s">
                <Trans.StudyConsent.Paragraph1 />
              </Typography>
              <Typography style={{ marginTop: "8px" }} variant="body-s">
                <Trans.StudyConsent.Paragraph2 />
              </Typography>

              <ul className={styles.studies}>
                {(
                  [
                    [studies.DERMAFLOW, TransStudies.DermaFlow.Title],
                    [studies.SPECTRUM, TransStudies.Spectrum.Title],
                    [studies.CARDIO, TransStudies.Cardio.Title],
                  ] as const
                ).map(([study, Translation]) => (
                  <li key={study.name} className={styles.study}>
                    <Toggle
                      name="consent"
                      label=""
                      checked={toggleState[study.name]}
                      onClick={(value) => toggle(study, value)}
                    />
                    <span onClick={() => setStudy(study)}>
                      <Translation />
                    </span>
                  </li>
                ))}
              </ul>

              <Typography as="span" variant="body-s">
                <Trans.StudyConsent.ParticipationInfo />
              </Typography>
            </div>
          </div>

          <div className={styles.cta}>
            <div className={styles.action}>
              <ActionButton onClick={onSave} disabled={!hasAgreedToAnyStudy}>
                <Trans.StudyConsent.ConsentAndParticipateButton />
              </ActionButton>
            </div>
          </div>
        </BurgerLayout>
        <Backdrop
          active={selectedStudy != null}
          kind="subtle"
          onOutsideClick={onCloseStudyDetail}
        >
          <Sticky>
            {selectedStudy && (
              <ConsentDocument
                key={selectedStudy.consentMetadata.consentId}
                onClose={onCloseStudyDetail}
                consent={selectedStudy}
              />
            )}
          </Sticky>
        </Backdrop>
      </ViewStack>
    </FullScreenPageLayout>
  );
}
