import { Patient, Slot } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { paths } from "render/routes/paths";
import { Typography } from "render/ui/presentation/Typography";
import { FadeIn } from "render/views/HomeView/components/_shared/FadeIn";
import { LinkTo } from "render/views/HomeView/components/_shared/LinkTo/LinkTo";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface BookingUpcomingStageProps {
  patient: Patient;
  slot: Slot;
}

export function BookingUpcomingStage({
  patient,
  slot,
}: BookingUpcomingStageProps) {
  const nav = {
    appointment: useNav(paths.appointment),
  };
  const time = useMemo(() => {
    const format = "HH:mm";
    const timeZoneId = slot.room?.site?.timeZoneId;
    const start = slot.startTime.setZone(timeZoneId);
    const end = slot.endTime.setZone(timeZoneId);

    return `${start.toFormat(format)} - ${end.toFormat(format)}`;
  }, [slot]);
  const date = useMemo(() => {
    const timeZoneId = slot.room?.site?.timeZoneId;
    const start = slot.startTime.setZone(timeZoneId);

    const isDifferentYear = start.year !== DateTime.now().year;

    return start.toFormat(`d MMMM${isDifferentYear ? " yyyy" : ""}`);
  }, [slot]);

  return (
    <div className={styles.BookingUpcomingStage}>
      <FadeIn offset={112}>
        <Typography variant="display-s">
          <Trans.Greeting patient={patient} />
        </Typography>
      </FadeIn>
      <FadeIn offset={176}>
        <LinkTo
          variant="square"
          href={nav.appointment.to({ slotId: slot.slotId })}
          head={<Trans.ScanBooked />}
          description={`${time} • ${date}`}
        />
      </FadeIn>
    </div>
  );
}
