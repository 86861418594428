import { PathRoute } from "@pomle/react-router-paths";
import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { Direction } from "@pomle/react-viewstack-transitions";
import { useEffect } from "react";
import { useTrackingContext } from "render/context/TrackingContext";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { paths } from "render/routes/paths";
import { ReportRoutes } from "render/routes/routes/SessionRoutes/routes/ReportRoutes";
import { AssignmentView } from "render/views/AssignmentView";
import { CheckoutFailView } from "render/views/CheckoutFailView";
import { CheckoutSuccessView } from "render/views/CheckoutSuccessView";
import { ClaimBookingToken } from "render/views/ClaimBookingToken";
import { HomeView } from "render/views/HomeView";
import { MemberInviteView } from "render/views/MemberInviteView";
import { OnboardingView } from "render/views/OnboardingView";
import { ProfileView } from "render/views/ProfileView";
import { AppointmentView } from "render/views/appointment/AppointmentView";
import { AppointmentPrereqView } from "render/views/appointment/prereq/AppointmentPrereqView";
import { AppointmentIdentityRequiredView } from "render/views/appointment/prereq/idv/AppointmentIdentityRequiredView";
import { AppointmentOnboarding } from "render/views/appointment/prereq/onboarding/AppointmentOnboarding";
import { PasskeyResultView } from "render/views/appointment/prereq/passkey/OnboardingPasskeyResultView";
import { PasskeyView } from "render/views/appointment/prereq/passkey/OnboardingPasskeyView";
import { CheckoutRouter } from "render/views/checkout/_CheckoutRouter/CheckoutRouter";
import { ConciergeCheckInView } from "render/views/concierge/ConciergeCheckInView";
import { AtriumSignIn } from "render/views/partners/atrium/AtriumSignIn";
import { mount } from "./lib/mount";
import { BookingRoutes } from "./routes/BookingRoutes";

export function SessionRoutes() {
  const { hasLoaded, gtm, conversionsTracking } = useTrackingContext();
  const patientQuery = usePatientQuery();

  useEffect(() => {
    if (hasLoaded || patientQuery.data == null) {
      return;
    }
    gtm.Init(patientQuery.data);
    gtm.GiveConsent();
    conversionsTracking.TrackLogin();
  }, [hasLoaded, patientQuery.data, gtm, conversionsTracking]);

  return (
    <ViewStack>
      <PathRoute path={paths.root}>
        {(match) => <Sticky>{match?.exact && <HomeView />}</Sticky>}
      </PathRoute>
      <PathRoute path={paths.profile}>
        {mount(ProfileView, { animation: "slide", direction: Direction.Up })}
      </PathRoute>
      <PathRoute path={paths.concierge.checkIn}>
        {mount(ConciergeCheckInView, {
          animation: "fade",
        })}
      </PathRoute>
      <PathRoute path={paths.assignment}>
        {mount(AssignmentView, {
          animation: "slide",
          direction: Direction.Right,
        })}
      </PathRoute>
      <PathRoute path={paths.atriumSignIn}>
        {mount(AtriumSignIn, { animation: "slide", direction: Direction.Up })}
      </PathRoute>

      <PathRoute path={paths.memberInviteCryptic}>
        {mount(MemberInviteView, { animation: "fade" })}
      </PathRoute>

      <PathRoute path={paths.claimBookingToken}>
        {mount(ClaimBookingToken, {
          animation: "slide",
          direction: Direction.Up,
        })}
      </PathRoute>

      <PathRoute path={paths.checkout}>
        {mount(CheckoutRouter, {
          animation: "slide",
          direction: Direction.Up,
        })}
      </PathRoute>
      <PathRoute path={paths.checkoutSuccess}>
        {mount(CheckoutSuccessView, {
          animation: "slide",
          direction: Direction.Down,
        })}
      </PathRoute>
      <PathRoute path={paths.checkoutFail}>
        {mount(CheckoutFailView, {
          animation: "slide",
          direction: Direction.Down,
        })}
      </PathRoute>

      <BookingRoutes />

      <PathRoute path={paths.onboarding}>
        {mount(OnboardingView, {
          animation: "slide",
          direction: Direction.Down,
        })}
      </PathRoute>

      <ReportRoutes />

      <PathRoute path={paths.appointment}>
        {mount(AppointmentView, {
          animation: "slide",
          direction: Direction.Right,
        })}
      </PathRoute>

      <PathRoute path={paths.appointmentPrereq.root}>
        {mount(AppointmentPrereqView, {
          animation: "slide",
          direction: Direction.Right,
        })}
      </PathRoute>

      <PathRoute path={paths.appointmentPrereq.onboarding}>
        {mount(AppointmentOnboarding, {
          animation: "slide",
          direction: Direction.Right,
        })}
      </PathRoute>

      <PathRoute path={paths.appointmentPrereq.addPasskey}>
        {mount(PasskeyView, {
          animation: "slide",
          direction: Direction.Right,
        })}
      </PathRoute>

      <PathRoute path={paths.appointmentPrereq.addPasskeyResult}>
        {mount(PasskeyResultView, {
          animation: "slide",
          direction: Direction.Right,
        })}
      </PathRoute>

      <PathRoute path={paths.appointmentPrereq.identityVerification}>
        {mount(AppointmentIdentityRequiredView, {
          animation: "slide",
          direction: Direction.Right,
        })}
      </PathRoute>
    </ViewStack>
  );
}
