import { APITypesV1 } from "@cur8/api-client";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface DiscountCodeExpiredProps {
  token: APITypesV1.DiscountToken;
}

export function DiscountCodeExpired({ token }: DiscountCodeExpiredProps) {
  return (
    <>
      <div className={styles.title}>
        <Typography variant="eyebrow-m" color="subtle">
          <Trans.Title />
        </Typography>
        <Typography variant="body-xs" color="subtle">
          <Trans.Status />
        </Typography>
      </div>
      <div className={styles.row}>
        <div className={styles.code}>
          <Typography variant="body-label-m">{token.code}</Typography>
        </div>
      </div>
    </>
  );
}
