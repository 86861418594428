import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as ChevronLeft } from "assets/icons/16x16/16x16_chevron-left.svg";
import { ReactComponent as ChevronRight } from "assets/icons/16x16/16x16_chevron-right.svg";
import { ReactComponent as ExIcon } from "assets/icons/24x24/24x24_close.svg";
import { DateTime } from "luxon";
import { ReactNode } from "react";
import { paths } from "render/routes/paths";
import { Typography } from "render/ui/presentation/Typography";
import { StudySymbol } from "render/ui/symbol/StudySymbol";
import { IconButton } from "render/ui/trigger/IconButton";
import { MetricViewProps } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details/types";
import { Trans as ScanDataTrans } from "render/views/Report/AppointmentDataView/components/ScanDataTab/trans";
import { Trans } from "../trans";
import styles from "./styles.module.sass";

type MetricSectionProps = {
  scanDate?: DateTime;
  children?: ReactNode;
  displayValue: [ReactNode, ReactNode][];
  changeIcon?: ReactNode;
  changeDisplayValue?: ReactNode;
  previousDisplayValue: [ReactNode, ReactNode][];
} & Omit<MetricViewProps, "values">;

export function MetricSection({
  scanDate,
  label,
  isClinicalStudy,
  scanNum,
  setScanNum,
  children,
  numOfScans,
  changeDisplayValue,
  previousDisplayValue,
  displayValue,
  changeIcon,
}: MetricSectionProps) {
  const canGoToPreviousScan = scanNum > 1;
  const canGoToNextScan = scanNum < numOfScans;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <header className={styles.nav}>
          <div>
            <IconButton
              onClick={useNav(paths.appointmentSummaryData).on({})}
              icon={<ExIcon className={styles.close} />}
              ariaLabel={Trans.Close()}
            />
          </div>
          {scanDate != null ? (
            <nav className={styles.date}>
              {numOfScans > 1 ? (
                <IconButton
                  onClick={() => canGoToPreviousScan && setScanNum(scanNum - 1)}
                  icon={
                    <ChevronLeft
                      className={styles.chevronLeft}
                      data-disabled={!canGoToPreviousScan}
                    />
                  }
                  ariaLabel={Trans.PreviousScan()}
                  disabled={!canGoToPreviousScan}
                />
              ) : null}
              <Typography variant="eyebrow-m">
                {scanDate ? (
                  scanDate.toLocaleString({
                    month: "short",
                    year: "numeric",
                  })
                ) : (
                  <Trans.UnknownDate />
                )}
              </Typography>
              {numOfScans > 1 ? (
                <IconButton
                  onClick={() => canGoToNextScan && setScanNum(scanNum + 1)}
                  icon={
                    <ChevronRight
                      className={styles.chevronRight}
                      data-disabled={!canGoToNextScan}
                    />
                  }
                  ariaLabel={Trans.PreviousScan()}
                  disabled={!canGoToNextScan}
                />
              ) : null}
            </nav>
          ) : null}
        </header>

        <div className={styles.slideIn}>
          <section className={styles.content}>
            {displayValue.length ? (
              <>
                <div className={styles.row}>
                  <Typography variant="title-m">{label}</Typography>
                  {isClinicalStudy ? <StudySymbol /> : null}
                </div>
                <div className={styles.values}>
                  {displayValue?.map(([v, l], index) => {
                    return (
                      <div className={styles.valuesContainer} key={index}>
                        <p className={styles.value}>{v}</p>
                        <Typography variant="title-m">{l}</Typography>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.row}>
                  {changeIcon}
                  {changeDisplayValue ? (
                    <>
                      {changeDisplayValue}
                      <div className={styles.divider} />
                    </>
                  ) : null}
                  {previousDisplayValue.length ? (
                    <div className={styles.row} data-secondary>
                      <Typography variant="body-s">
                        <Trans.PreviousLabel />
                      </Typography>
                      {previousDisplayValue?.map(([v, l], index) => {
                        return (
                          <div className={styles.previousValueRow} key={index}>
                            <Typography variant="body-s">{v}</Typography>
                            <Typography variant="body-s">{l}</Typography>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className={styles.row} data-secondary>
                      <Typography variant="body-s">
                        <Trans.Baseline />
                      </Typography>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className={styles.noDataRow}>
                <div className={styles.row}>
                  <Typography variant="title-l" color="subtle">
                    {label}
                  </Typography>
                  {isClinicalStudy ? <StudySymbol /> : null}
                </div>
                <Typography variant="body-s">
                  <Trans.NoDataText />
                </Typography>
              </div>
            )}
            {displayValue === undefined ? (
              <div className={styles.backgroundImage} />
            ) : null}
            {children}
            <div className={styles.studies}>
              <Typography variant="body-s">
                {isClinicalStudy ? (
                  <ScanDataTrans.StudyInfoSweden />
                ) : (
                  <ScanDataTrans.StudyInfo />
                )}
              </Typography>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
