import "polyfils";

import { getConfig } from "auth/config";
import { getInsights } from "auth/insights";
import { getMSAL } from "auth/msal";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { App } from "render/app";
import { reportWebVitals } from "./reportWebVitals";

Promise.resolve()
  .then(async () => {
    const config = await getConfig();
    const [insights, msal] = await Promise.all([
      getInsights(config),
      getMSAL(config),
    ]);
    return { config, msal, insights };
  })
  .then(({ config, insights, msal }) => {
    const element = document.getElementById("root");
    if (element) {
      const root = createRoot(element);
      root.render(
        <StrictMode>
          <App config={config} insights={insights} msal={msal} />
        </StrictMode>
      );
    }
  })
  .then(() => {
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  });
