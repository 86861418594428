import {
  MSALContext as RealMSALContext,
  useMSAL as realUseMSAL,
  useSession as realUseSession,
} from "./MSALContext";
import { Authenticated as RealAuthenticated } from "./components/Authenticated";

import {
  MockAuthenticated,
  MSALContext as MockedMSALContext,
  useMSAL as mockUseMSAL,
  useSession as mockUseSession,
} from "./__mock__";

import { PropsWithChildren, createElement } from "react";
import { AuthenticatedProps } from "./types";
import { IPublicClientApplication } from "@azure/msal-browser";

const isPlaywright = process.env.REACT_APP_TEST_ENV === "playwright";

export function MSALContext({
  children,
  msal,
}: PropsWithChildren<{ msal: IPublicClientApplication }>) {
  const Provider = isPlaywright ? MockedMSALContext : RealMSALContext;
  return <Provider msal={msal}>{children}</Provider>;
}

export function Authenticated(props: PropsWithChildren<AuthenticatedProps>) {
  const Component = isPlaywright ? MockAuthenticated : RealAuthenticated;
  return createElement(Component, props);
}

export function useSession() {
  const hook = isPlaywright ? mockUseSession : realUseSession;
  return hook();
}

export function useMSAL() {
  const hook = isPlaywright ? mockUseMSAL : realUseMSAL;
  return hook();
}
