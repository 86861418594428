import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useNav } from "@pomle/react-router-paths";
import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { Direction, Fade, Slide } from "@pomle/react-viewstack-transitions";
import { ReactComponent as ExIcon } from "assets/icons/24x24/24x24_close.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/24x24/24x24_download.svg";
import { ReactComponent as ShareIcon } from "assets/icons/24x24/24x24_share.svg";
import { ReactComponent as LogoIcon } from "assets/logo.svg";
import { ReactComponent as Squircle } from "assets/squircle.svg";
import {
  CSSProperties,
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { usePostScanShareContext } from "render/context/PostScanShareContext";
import { useScanCompleteAssetDetailsQuery } from "render/hooks/api/queries/useScanCompleteAssetDetailsQuery";
import { useScanCompleteAssetQuery } from "render/hooks/api/queries/useScanCompleteAssetQuery";
import { useMediaQuery } from "render/hooks/useMediaQuery";
import { useTracking } from "render/hooks/useTracking";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { LogoView } from "render/views/LogoView";
import { SharedTrans } from "render/views/trans";
import { Timings } from "./animations";
import { IntroText } from "./components/IntroText";
import { ScanCompleteAsset } from "./components/ScanCompleteAsset";
import styles from "./styles.module.sass";
import { postScanShareEvent } from "./tracking";
import { Trans } from "./trans";

/**
 * note(miha): when the post scan intro view is shown for the first time, it's rendered as a standalone view
 * with a long reveal animation and "share" and "visit report" CTAs
 * on subsequent visits, the view should be rendered as a modal, with a shorter reveal animation, "share" CTA only, and a modal close button
 * the `variant` prop is used to control this behavior
 */
export type Variant = "page" | "modal";

export function PostScanShareView({ variant = "page" }: { variant?: Variant }) {
  const { visit, markAsSeen, setIsOpen } = usePostScanShareContext();

  const appInsights = useAppInsights();
  const { trackEvent } = useTracking();

  const isModalVariant = variant === "modal";
  const isSmallViewport = useMediaQuery(`(max-width: 440px)`);

  useEffect(() => {
    trackEvent(postScanShareEvent.pageView());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shareableAssetDetailsQuery = useScanCompleteAssetDetailsQuery(
    visit?.visitId,
    {
      enabled: !!visit?.visitId,
    }
  );
  const shareableAssetQuery = useScanCompleteAssetQuery(visit?.visitId, {
    enabled: !!visit?.visitId,
  });

  useEffect(() => {
    if (shareableAssetDetailsQuery.isError || shareableAssetQuery.isError) {
      setIsOpen(false);
    }
  }, [
    shareableAssetDetailsQuery.isError,
    shareableAssetQuery.isError,
    setIsOpen,
  ]);

  const appointmentSummary = useNav(paths.appointmentSummary);

  const nativeShareSupported = !!navigator.share;

  const handleNativeShare = useCallback(async () => {
    if (!shareableAssetQuery.data) {
      return;
    }

    try {
      await navigator.share({
        files: [
          new File(
            [shareableAssetQuery.data],
            "Neko Health - Scan Complete.png",
            {
              type: "image/png",
            }
          ),
        ],
      });
    } catch (error) {
      if (error instanceof Error) {
        appInsights.trackException({
          exception: error,
          properties: { description: "Error sharing the image" },
          severityLevel: SeverityLevel.Error,
        });
      }
    }
  }, [shareableAssetQuery.data, appInsights]);

  const handleFallbackShare = useCallback(async () => {
    if (!shareableAssetQuery.data) {
      return;
    }

    const objectUrl = URL.createObjectURL(shareableAssetQuery.data);

    const a = document.createElement("a");
    a.href = objectUrl;
    a.download = "Neko Health - Scan Booked.png";
    a.click();
  }, [shareableAssetQuery.data]);

  const handleShare = useCallback(async () => {
    if (!shareableAssetQuery.data) {
      return;
    }

    trackEvent(
      postScanShareEvent.shareClick(
        nativeShareSupported ? "nativeShare" : "download"
      )
    );

    if (nativeShareSupported) {
      await handleNativeShare();
    } else {
      await handleFallbackShare();
    }
  }, [
    shareableAssetQuery.data,
    nativeShareSupported,
    handleNativeShare,
    handleFallbackShare,
    trackEvent,
  ]);

  const handleContinue = useCallback(() => {
    trackEvent(postScanShareEvent.continueClick());
    appointmentSummary.go({});
    setIsOpen(false);
  }, [trackEvent, setIsOpen, appointmentSummary]);

  const [isAssetsReady, setIsAssetsReady] = useState(false);

  const handleAssetReady = () => {
    setIsAssetsReady(true);
  };

  useEffect(() => {
    if (!isAssetsReady) {
      return;
    }

    markAsSeen();
  }, [isAssetsReady, markAsSeen]);

  const buttonsDisabled = shareableAssetQuery.isFetching;

  const assetParameters = useMemo(() => {
    if (!shareableAssetDetailsQuery.data) {
      return undefined;
    }

    return {
      name: shareableAssetDetailsQuery.data.parameters.name,
      date: shareableAssetDetailsQuery.data.parameters.date,
      scan: shareableAssetDetailsQuery.data.parameters.scan,
      siteName: shareableAssetDetailsQuery.data.parameters.siteName,
    };
  }, [shareableAssetDetailsQuery.data]);

  const [introAnimationEnded, setIntroAnimationEnded] = useState(false);
  const handleIntroAnimationEnded = () => setIntroAnimationEnded(true);

  const postIntroAnimationPlayState = useMemo(
    () => (introAnimationEnded ? "running" : "paused"),
    [introAnimationEnded]
  );

  // hack(ford): The logo should be placed at the top of the stack, but position is determined by the card which is deep
  //             Maybe there is a better way to do this?
  const [logoRect, setLogoRect] = useState<DOMRect>();
  const handleLogoPositionChanged = useCallback((rect: DOMRect) => {
    setLogoRect(rect);
  }, []);

  // note(miha): the intro animation should be skipped when the view is rendered as a modal
  useLayoutEffect(() => {
    if (isModalVariant) {
      setIntroAnimationEnded(true);
    }
  }, [isModalVariant]);

  if (isModalVariant) {
    return (
      <FullScreenPageLayout>
        <ViewStack>
          <Fade active={isAssetsReady}>
            <div className={styles.stacked}>
              <div className={styles.content} data-is-animated={false}>
                <button
                  className={styles.closeButton}
                  onClick={() => setIsOpen(false)}
                  aria-label={SharedTrans.Close()}
                >
                  <ExIcon className={styles.exIcon} />
                  <Squircle className={styles.squircle} />
                </button>

                <div className={styles.shareContainer}>
                  <div
                    className={styles.shareContent}
                    style={
                      {
                        "--play-state": postIntroAnimationPlayState,
                        "--duration": `${Timings.cardTransitionDurationSec}s`,
                      } as CSSProperties
                    }
                  >
                    {assetParameters && (
                      <ScanCompleteAsset
                        data-is-animated={false}
                        name={assetParameters.name}
                        date={assetParameters.date}
                        scan={assetParameters.scan}
                        siteName={assetParameters.siteName}
                        onReady={handleAssetReady}
                      />
                    )}
                    <div className={styles.buttons}>
                      <div className={styles.shareButton}>
                        <ActionButton
                          hideIcon
                          onClick={handleShare}
                          disabled={buttonsDisabled}
                          variant={"suggestion"}
                        >
                          <Typography variant="body-label-m">
                            <Trans.Share />
                          </Typography>
                          {nativeShareSupported ? (
                            <ShareIcon />
                          ) : (
                            <DownloadIcon />
                          )}
                        </ActionButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </ViewStack>
      </FullScreenPageLayout>
    );
  }

  return (
    <FullScreenPageLayout>
      <ViewStack>
        {!isAssetsReady && <LogoView />}
        <Fade active={isAssetsReady}>
          <div className={styles.stacked}>
            <div className={styles.content} data-is-animated={isSmallViewport}>
              <div className={styles.shareContainer}>
                <div
                  className={styles.shareContent}
                  style={
                    {
                      "--play-state": postIntroAnimationPlayState,
                      "--duration": `${Timings.cardTransitionDurationSec}s`,
                    } as CSSProperties
                  }
                >
                  {assetParameters && (
                    <ScanCompleteAsset
                      data-is-animated={isSmallViewport}
                      name={assetParameters.name}
                      date={assetParameters.date}
                      scan={assetParameters.scan}
                      siteName={assetParameters.siteName}
                      playAnimation={introAnimationEnded}
                      onReady={handleAssetReady}
                      onLogoPositionChanged={handleLogoPositionChanged}
                    />
                  )}
                  <div className={styles.buttons}>
                    <div
                      className={styles.shareButton}
                      style={
                        {
                          "--play-state": postIntroAnimationPlayState,
                          "--delay": `${Timings.buttonShareDelaySec}s`,
                          "--duration": `${Timings.buttonShareDurationSec}s`,
                        } as CSSProperties
                      }
                    >
                      <ActionButton
                        hideIcon
                        onClick={handleShare}
                        disabled={buttonsDisabled}
                        variant="primary"
                      >
                        <Typography variant="body-label-m">
                          <Trans.Share />
                        </Typography>
                        {nativeShareSupported ? (
                          <ShareIcon />
                        ) : (
                          <DownloadIcon />
                        )}
                      </ActionButton>
                    </div>

                    <div
                      className={styles.continueButton}
                      style={
                        {
                          "--play-state": postIntroAnimationPlayState,
                          "--delay": `${Timings.buttonContinueDelaySec}s`,
                          "--duration": `${Timings.buttonContinueDurationSec}s`,
                        } as CSSProperties
                      }
                    >
                      <ActionButton
                        hideIcon
                        variant="suggestion"
                        onClick={handleContinue}
                        disabled={buttonsDisabled}
                      >
                        <div className={styles.fullWidth}>
                          <Typography variant="body-label-m">
                            <Trans.ViewReport />
                          </Typography>
                        </div>
                      </ActionButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={styles.blur}
              style={
                {
                  "--play-state": postIntroAnimationPlayState,
                  "--duration": `${Timings.cardTransitionDurationSec}s`,
                } as CSSProperties
              }
            >
              <IntroText
                text={Trans.IntroText()}
                playAnimation={isAssetsReady}
                onAnimationEnded={handleIntroAnimationEnded}
              />
            </div>

            <div
              className={styles.logo}
              data-is-animated={isSmallViewport}
              style={
                {
                  "--top": `${logoRect?.top}px`,
                  "--height": `${logoRect?.height}px`,
                  "--duration": `${Timings.cardLogoDurationSec}s`,
                  "--play-state": postIntroAnimationPlayState,
                } as CSSProperties
              }
            >
              <LogoIcon />
            </div>
          </div>
        </Fade>
      </ViewStack>
    </FullScreenPageLayout>
  );
}

function Modal({
  children,
  isActive,
}: PropsWithChildren<{ isActive: boolean }>) {
  return (
    <Slide active={isActive} direction={Direction.Down}>
      <Sticky delay={500}>{isActive && children}</Sticky>
    </Slide>
  );
}

function FullscreenView({
  children,
  isActive,
}: PropsWithChildren<{ isActive: boolean }>) {
  return (
    <Fade active={isActive}>
      <Sticky delay={500}>{isActive && children}</Sticky>
    </Fade>
  );
}

PostScanShareView.Modal = Modal;
PostScanShareView.FullscreenView = FullscreenView;
