import { PropsWithChildren } from "react";
import styles from "./styles.module.sass";

type WhiteSpaceType =
  | "normal"
  | "pre"
  | "nowrap"
  | "pre-wrap"
  | "pre-line"
  | "break-spaces";

export type TypographyProps = {
  as?: VariantProps["tag"];
  variant:
    | "number-l"
    | "number-s"
    | "display-s"
    | "title-l"
    | "title-m"
    | "title-s"
    | "body-m"
    | "body-s"
    | "body-xs"
    | "body-link-m"
    | "body-link-s"
    | "body-link-xs"
    | "body-label-m"
    | "body-label-s"
    | "eyebrow-m"
    | "eyebrow-s";

  whiteSpace?: WhiteSpaceType;
  style?: Partial<React.CSSProperties>;
  color?: "info" | "subtle" | "warning" | "default" | "inverted";
};

const VARIANT_PROPS_MAP: Record<TypographyProps["variant"], VariantProps> = {
  "number-l": {
    tag: "span",
    className: styles.numberL,
  },
  "number-s": {
    tag: "span",
    className: styles.numberS,
  },
  "display-s": {
    tag: "h1",
    className: styles.displayS,
  },
  "title-l": {
    tag: "h2",
    className: styles.titleL,
  },
  "title-m": {
    tag: "h3",
    className: styles.titleM,
  },
  "title-s": {
    tag: "h4",
    className: styles.titleS,
  },
  "body-m": {
    tag: "p",
    className: styles.bodyM,
  },
  "body-s": {
    tag: "p",
    className: styles.bodyS,
  },
  "body-xs": {
    tag: "p",
    className: styles.bodyXS,
  },
  "body-link-m": {
    tag: "span",
    className: styles.bodyLinkM,
  },
  "body-link-s": {
    tag: "span",
    className: styles.bodyLinkS,
  },
  "body-link-xs": {
    tag: "span",
    className: styles.bodyLinkXS,
  },
  "body-label-m": {
    tag: "p",
    className: styles.bodyLabelM,
  },
  "body-label-s": {
    tag: "p",
    className: styles.bodyLabelS,
  },
  "eyebrow-m": {
    tag: "p",
    className: styles.eyebrowM,
  },
  "eyebrow-s": {
    tag: "p",
    className: styles.eyebrowS,
  },
};

type VariantProps = {
  className: string;
  tag: "h1" | "h2" | "h3" | "h4" | "p" | "span";
};

export function Typography({
  as,
  children,
  color,
  style = {},
  variant,
  whiteSpace = "normal",
}: PropsWithChildren<TypographyProps>) {
  const { className, tag } = VARIANT_PROPS_MAP[variant];
  const Component = as ?? tag;

  return (
    <Component
      className={className}
      data-color={color}
      style={{ whiteSpace, ...style }}
    >
      {children}
    </Component>
  );
}
