import { APITypesV1 } from "@cur8/api-client";
import { useCallback, useMemo } from "react";
import { Typography } from "render/ui/presentation/Typography";
import { DiscountCode } from "render/views/ProfileView/components/DiscountTokensSection/components/DiscountCode";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface DiscountCodeListProps {
  tokens: APITypesV1.DiscountToken[];
}

export function DiscountTokensSection({ tokens }: DiscountCodeListProps) {
  const isPartialDiscountToken = useCallback(
    (token: APITypesV1.DiscountToken) =>
      token.discountTokenType ===
      APITypesV1.DiscountTokenType.SinglePartialDiscount,
    []
  );
  const hasPartialDiscountTokens = useMemo(
    () =>
      tokens.length === 0 ||
      tokens.some((token) => isPartialDiscountToken(token)),
    [tokens, isPartialDiscountToken]
  );
  const tokensOrdered = useMemo(() => {
    let result = tokens;
    if (hasPartialDiscountTokens) {
      result = tokens.filter((token) => isPartialDiscountToken(token));
    }
    return result.sort((token) => (token.isValid ? -1 : 0));
  }, [tokens, isPartialDiscountToken, hasPartialDiscountTokens]);

  return (
    <div className={styles.body}>
      <div className={styles.content}>
        <div className={styles.headings}>
          <Typography variant="display-s">
            <Trans.Title />
          </Typography>
          <Typography variant="body-m" color="subtle">
            {hasPartialDiscountTokens ? (
              <Trans.DiscountPartial />
            ) : (
              <Trans.DiscountFull />
            )}
          </Typography>
        </div>
        {tokensOrdered.length === 0 && (
          <div className={styles.headings}>
            <Typography
              style={{ marginTop: "44px" }}
              variant="eyebrow-m"
              color="subtle"
            >
              <Trans.EmptyList />
            </Typography>
          </div>
        )}
        {tokensOrdered.length > 0 && (
          <ul data-hj-suppress className={styles.tokens}>
            {tokensOrdered.map((token) => (
              <li key={token.code} className={styles.token}>
                <DiscountCode token={token} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
