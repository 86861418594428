import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as ExIcon } from "assets/icons/24x24/24x24_close.svg";
import { splitSlotId } from "lib/visit/splitSlotId";
import { useCallback } from "react";
import { useAppointmentQuery } from "render/hooks/api/queries/useAppointmentQuery";
import {
  useVisitPrerequisites,
  VisitPrerequisiteStep,
} from "render/hooks/api/useVisitPrerequisites";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import { StepButton } from "render/views/appointment/AppointmentView/components/AppointmentInfoSection/components/StepsSection/components/StepButton/StepButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

function PrerequisiteStepText({ step }: { step: VisitPrerequisiteStep }) {
  switch (step.id) {
    case "idv":
      return step.completed ? (
        <Trans.TodoList.Done.IdentityVerification />
      ) : (
        <Trans.TodoList.Todo.IdentityVerification />
      );
    case "passkey":
      return step.completed ? (
        <Trans.TodoList.Done.Passkey />
      ) : (
        <Trans.TodoList.Todo.Passkey />
      );
    case "questionnaire":
      return step.completed ? (
        <Trans.TodoList.Done.CompleteOnboarding />
      ) : (
        <Trans.TodoList.Todo.CompleteOnboarding />
      );
    case "studies":
      return step.completed ? (
        <Trans.TodoList.Done.RenewStudies />
      ) : (
        <Trans.TodoList.Todo.RenewStudies />
      );
    default:
      return null;
  }
}

export function AppointmentPrereqView({ slotId }: { slotId: string }) {
  const { visitId } = splitSlotId(slotId);
  const appointmentQuery = useAppointmentQuery({ slotId });
  const visitPrerequisites = useVisitPrerequisites({
    origin: "prereq",
    visitId,
  });
  const nav = {
    appointment: useNav(paths.appointment),
  };

  const controls = {
    onCompleteClick: useCallback(() => {
      nav.appointment.go({ slotId });
    }, [nav.appointment, slotId]),
    onNextStep: useCallback(() => {
      visitPrerequisites.getNextStep()?.(slotId);
    }, [slotId, visitPrerequisites]),
  };

  return (
    <FullScreenPageLayout>
      <div className={styles.AppointmentPrereqView}>
        {appointmentQuery.data && visitPrerequisites.hasLoaded ? (
          <>
            <div className={styles.header}>
              <LogoHeader
                leftElement={
                  <IconButton
                    onClick={nav.appointment.on({ slotId })}
                    icon={<ExIcon />}
                  />
                }
              />
            </div>
            <div className={styles.content}>
              <span className={styles.top}>
                <div className={styles.text}>
                  <Typography variant="display-s">
                    {visitPrerequisites.isReady ? (
                      <Trans.Title.Ready />
                    ) : (
                      <Trans.Title.NotReady />
                    )}
                  </Typography>
                  <Typography variant="body-m">
                    {visitPrerequisites.isReady ? (
                      <Trans.Description.Ready />
                    ) : (
                      <Trans.Description.NotReady />
                    )}
                  </Typography>
                </div>

                <ul className={styles.steps}>
                  {visitPrerequisites.steps.map((step) => {
                    return (
                      <li key={step.id} className={styles.step}>
                        <StepButton completed={step.completed}>
                          <PrerequisiteStepText step={step} />
                        </StepButton>
                      </li>
                    );
                  })}
                </ul>
              </span>

              <>
                {!visitPrerequisites.isReady && (
                  <ActionButton
                    variant="suggestion"
                    onClick={controls.onNextStep}
                  >
                    <Trans.CTA.ToDo />
                  </ActionButton>
                )}
                {visitPrerequisites.isReady && (
                  <ActionButton
                    variant="suggestion"
                    onClick={controls.onCompleteClick}
                  >
                    <Trans.CTA.Done />
                  </ActionButton>
                )}
              </>
            </div>
          </>
        ) : null}
      </div>
    </FullScreenPageLayout>
  );
}
