import { VisitSummary } from "@cur8/rich-entity";
import { lastAuditChangeDate } from "lib/lastAuditChangeDate";
import { Accordion } from "render/ui/presentation/AccordionV2";
import { Typography } from "render/ui/presentation/Typography/Typography";
import { Summary } from "../../../../../../../ui/presentation/doctor/Summary";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface PreviousSummariesProps {
  visitsSummaries: VisitSummary[];
}

export function PreviousSummaries({ visitsSummaries }: PreviousSummariesProps) {
  return (
    <div className={styles.previousSummaries}>
      <Typography variant="title-m" as="h2">
        <Trans.ReportHistory />
      </Typography>
      <Accordion>
        {visitsSummaries.map((summary, index, arr) => {
          const header =
            index === arr.length - 1 ? <Trans.Baseline /> : <Trans.FollowUp />;

          return (
            <Accordion.Item
              key={summary.id}
              id={summary.id}
              header={header}
              subline={
                <>
                  <Trans.WrittenOn />
                  <span className={styles.date}>
                    {lastAuditChangeDate(summary.audit)?.toFormat(
                      "dd MMMM  yyyy"
                    )}
                  </span>
                </>
              }
            >
              <Summary
                text={summary.summaryText}
                doctorURI={summary.audit.created?.userUri?.toString()}
              />
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
}
