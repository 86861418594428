import { DateTime } from "luxon";
import { useMemo } from "react";
import { Typography } from "render/ui/presentation/Typography";
import { Trans } from "./trans";

interface NextScanRecomendationProps {
  scanDate: DateTime;
  followUpDate: DateTime;
}

export function NextScanRecomendation({
  followUpDate,
  scanDate,
}: NextScanRecomendationProps) {
  const months = useMemo(
    () => followUpDate.diff(scanDate, "months").months,
    [scanDate, followUpDate]
  );

  const isDue = useMemo(() => {
    return followUpDate.diffNow("month").months < 1;
  }, [followUpDate]);

  const dateFormated = useMemo(
    () => followUpDate.toFormat("MMMM yyyy"),
    [followUpDate]
  );

  return (
    <>
      <Typography variant="title-m">
        <Trans.NextScan />
      </Typography>
      <Typography variant="body-m" color="subtle">
        {isDue ? (
          <Trans.FollowUpDue date={dateFormated} />
        ) : (
          <Trans.FollowUpIn date={dateFormated} months={months} />
        )}
      </Typography>
    </>
  );
}
