import { BookableSlot, Site } from "@cur8/rich-entity";
import { codecs, createCodec, createQuery } from "@pomle/paths";
import { PathLink, useNav, useQueryParams } from "@pomle/react-router-paths";
import { Sticky } from "@pomle/react-viewstack";
import { ReactComponent as ChevronLeftIcon } from "assets/icons/16x16/16x16_chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "assets/icons/16x16/16x16_chevron-right.svg";
import { ReactComponent as PinIcon } from "assets/icons/16x16/16x16_pin.svg";
import { ReactComponent as ArrowBackIcon } from "assets/icons/24x24/24x24_arrow_left.svg";
import { ReactComponent as CrossIcon } from "assets/icons/24x24/24x24_close.svg";
import { ReactComponent as LogoIcon } from "assets/logo.svg";
import { Country } from "lib/country";
import { range } from "lib/mapRange";
import { DateTime } from "luxon";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useSiteSlotsQuery } from "render/hooks/api/queries/useSiteSlotsQuery";
import { useLiveTime } from "render/hooks/useLiveTime";
import { paths } from "render/routes/paths";
import { FullMonthDay } from "render/ui/format/FullMonthDay/FullMonthDay";
import { MonthName } from "render/ui/format/MonthName";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { Expandable } from "render/ui/presentation/Expandable";
import { Typography } from "render/ui/presentation/Typography";
import { IconButton } from "render/ui/trigger/IconButton";
import { BookingActions } from "render/views/booking/MultiSiteMonthView/components/BookingActions";
import { ListView } from "render/views/booking/MultiSiteMonthView/components/ListView";
import { MiniCalendar } from "render/views/booking/MultiSiteMonthView/components/MiniCalendar";
import { WeeklyCalendar } from "render/views/booking/MultiSiteMonthView/components/WeeklyCalendar";
import MobilePicture from "./assets/img/Book_Scan_Square.webp";
import WidePicture from "./assets/img/Book_Scan_Wide.webp";
import { Day } from "./components/Day";
import { useSitesQuery } from "./hooks/useSitesQuery";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

const DATE_FORMAT = "yyyy-LL-dd't'HH.mm.ssZZZ";

const dateCodec = createCodec(
  (source: DateTime) => source.toFormat(DATE_FORMAT).replace("+", "z"),
  (source: string) => {
    return DateTime.fromFormat(source.replace("z", "+"), DATE_FORMAT);
  }
);

const queryParams = createQuery({
  sites: codecs.string,
  month: dateCodec,
  day: dateCodec,
  week: dateCodec,
  slotId: codecs.string,
});

function WeeklyCalendarSection({
  week,
  renderDay,
}: {
  week: DateTime;
  renderDay: (date: DateTime) => ReactNode;
}) {
  return (
    <ul>
      {range(0, 0, (offset) => {
        const date = week.startOf("week").plus({ week: offset });
        return (
          <li key={date.toISODate()}>
            <WeeklyCalendar
              key={date.toISODate()}
              date={date}
              renderDay={renderDay}
            />
          </li>
        );
      })}
    </ul>
  );
}

// HACK-UK: This doesn't really scale but it'll do for now
function Location({ country, sites }: { country?: string; sites?: Site[] }) {
  const cityMap: Record<string, ReactNode> = {
    [Country.Sweden]: <Trans.City.Stockholm />,
    [Country.UK]: <Trans.City.London />,
  };

  const city = cityMap[country ?? ""];
  let description: undefined | ReactNode;

  if (sites) {
    if (sites.length === 1) {
      description = (
        <Trans.LocationDescription.SingleSite siteName={sites[0].siteName} />
      );
    } else if (sites.length > 1) {
      description = (
        <Trans.LocationDescription.MultiSite count={sites.length} />
      );
    }
  }

  return (
    <>
      {city}&nbsp;{description}
    </>
  );
}

function isOnMobile() {
  return window.innerWidth <= 980;
}

function getBookableUntilDate(now: DateTime) {
  return now.plus({ months: 2 }).endOf("month");
}

export function MultiSiteMonthView() {
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const [forceMobile, setForceMobile] = useState(isOnMobile());
  const [params, setParams] = useQueryParams(queryParams);
  const patientQuery = usePatientQuery();
  const sitesQuery = useSitesQuery(
    {
      country: patientQuery.data?.preferredCountry,
    },
    {
      enabled: !!patientQuery.data?.preferredCountry,
    }
  );

  const month = params.month.at(0);
  const day = params.day.at(0);
  const week = params.week.at(0);
  const slotId = params.slotId.at(0);

  const nav = {
    home: useNav(paths.root),
  };

  const siteIds = useMemo(() => {
    if (params.sites.length) {
      return params.sites;
    }
    let result = sitesQuery.data?.map((site) => {
      return site.siteId;
    });

    return result;
  }, [sitesQuery.data, params.sites]);

  const now = useLiveTime("minute");

  const fetchSlotsInterval = {
    start: !day ? undefined : DateTime.max(now, day.startOf("day")),
    end: day?.endOf("day"),
  };

  const enableFetchSlotsQuery =
    !!siteIds && !!fetchSlotsInterval.start && !!fetchSlotsInterval.end;
  const siteSlotsQuery = useSiteSlotsQuery(
    {
      siteIds: siteIds ?? [],
      start: fetchSlotsInterval.start,
      end: fetchSlotsInterval.end,
      pageSize: 100,
    },
    {
      enabled: enableFetchSlotsQuery,
    }
  );
  const refetchSiteSlots = siteSlotsQuery.refetch;

  const bookableUntil = useMemo(() => {
    return getBookableUntilDate(now);
  }, [now]);

  // only make request if month is not valid or we don't have siteIds
  const enableQueryForFirstAvailableSlot =
    !!month?.isValid === false && siteIds != null;
  const firstAvailableSlotQuery = useSiteSlotsQuery(
    {
      siteIds: siteIds ?? [],
      pageSize: 1,
      start: useMemo(() => DateTime.now(), []),
      end: bookableUntil,
    },
    {
      enabled: false,
      staleTime: Infinity,
    }
  );
  const fetchFirstAvailableSlot = firstAvailableSlotQuery.refetch;
  useEffect(() => {
    if (!enableQueryForFirstAvailableSlot) {
      return;
    }
    if (!firstAvailableSlotQuery.data) {
      fetchFirstAvailableSlot();
      return;
    }
    const slot = firstAvailableSlotQuery.data.at(0);
    const date = slot ? slot.startTime : DateTime.now();
    setParams({
      month: [date],
      day: [date],
      week: [date],
      slotId: slot ? [slot.slotId] : [],
    });
  }, [
    fetchFirstAvailableSlot,
    firstAvailableSlotQuery.data,
    setParams,
    enableQueryForFirstAvailableSlot,
  ]);

  const dailyListControls = {
    onSlotSelect: useCallback(
      (target: BookableSlot) => {
        setParams({ slotId: target.slotId === slotId ? [] : [target.slotId] });
      },
      [setParams, slotId]
    ),
    onSitesReset: useCallback(() => {
      setParams({ sites: [] });
    }, [setParams]),
    allSitesSelected: useMemo(() => {
      return params.sites.length === 0;
    }, [params.sites.length]),
  };

  const calendarControls = {
    resetDay: useCallback(() => {
      setParams({ day: [], slotId: [], week: [] });
      setForceMobile(false);
    }, [setParams]),
    canNavBack: useCallback(() => {
      return month != null && month.startOf("month") > now;
    }, [month, now]),
    canNavForward: useCallback(() => {
      return (
        month != null && month.startOf("month") < bookableUntil.startOf("month")
      );
    }, [month, bookableUntil]),
    isToday: useMemo(() => {
      const today = now.toISODate();
      return (target: DateTime) => {
        return target.toISODate() === today;
      };
    }, [now]),
    isDaySelected: useMemo(() => {
      const selectedDay = day?.toISODate();
      return (target: DateTime) => {
        return !!selectedDay && target.toISODate() === selectedDay;
      };
    }, [day]),
    onDaySelect: useCallback(
      (target: DateTime) => {
        setParams({ day: [target], week: [target], slotId: [] });
      },
      [setParams]
    ),
    navigateBy: useCallback(
      (offset: number) => () => {
        return month && setParams({ month: [month.plus({ month: offset })] });
      },
      [month, setParams]
    ),
  };

  const filterControls = {
    hasMultipleSites: sitesQuery.data && sitesQuery.data.length > 1,
    isSelected: useCallback(
      (id: string) => {
        return params.sites.some((siteId) => siteId === id);
      },
      [params]
    ),
    toggleFilterFor: useCallback(
      (siteId: string) => {
        return () => {
          setParams({ sites: [siteId], slotId: [] });
        };
      },
      [setParams]
    ),
    isAllSelected: params.sites.length === 0,
    resetSitesFilter: useCallback(() => {
      setParams({ sites: [] });
    }, [setParams]),
  };

  const dataControls = {
    refetchDaySlots: useCallback(() => {
      if (!enableFetchSlotsQuery) {
        return;
      }
      refetchSiteSlots();
    }, [enableFetchSlotsQuery, refetchSiteSlots]),
    displayOverlay: useCallback(() => setDisplayOverlay(true), []),
    hideOverlay: useCallback(() => setDisplayOverlay(false), []),
  };

  return (
    <FullScreenPageLayout>
      <div className={styles.MultiSiteMonthView}>
        <div
          className={styles.container}
          data-day-present={!!day || forceMobile}
        >
          <header className={styles.head} data-display-overlay={displayOverlay}>
            <div className={styles.overflowContainer}>
              <picture>
                <source srcSet={MobilePicture} media="(max-width: 980px)" />
                <source srcSet={WidePicture} />
                <img
                  className={styles.image}
                  alt="venue"
                  width="3040"
                  height="1200"
                />
              </picture>
              <div className={styles.header}>
                <div className={styles.logo}>
                  <PathLink className={styles.link} to={nav.home.to({})}>
                    <CrossIcon />
                  </PathLink>
                  <LogoIcon />
                </div>
                <div className={styles.title}>
                  <Typography variant="display-s">
                    <Trans.Header />
                  </Typography>
                  <div className={styles.badge}>
                    <PinIcon />
                    <Typography variant="eyebrow-m">
                      <Location
                        country={patientQuery.data?.preferredCountry}
                        sites={sitesQuery.data}
                      />
                    </Typography>
                  </div>
                </div>
              </div>
            </div>

            <section
              className={styles.mobileBack}
              data-active={!!day || forceMobile}
            >
              <button
                data-variant="dark"
                className={styles.backButton}
                onClick={calendarControls.resetDay}
              >
                <ArrowBackIcon />
              </button>
              <div className={styles.day}>
                <Typography variant="body-m">
                  {day?.toFormat("LLLL")}
                </Typography>
              </div>
            </section>
          </header>

          <div className={styles.content}>
            <div
              className={styles.bookingContainer}
              data-display-overlay={displayOverlay}
            >
              <section className={styles.locations}>
                <ul>
                  {sitesQuery.data && filterControls.hasMultipleSites && (
                    <>
                      <li>
                        <button
                          data-selected={filterControls.isAllSelected}
                          onClick={filterControls.resetSitesFilter}
                        >
                          <Typography variant="eyebrow-m" whiteSpace="pre">
                            <Trans.AllLocationsFilter />
                          </Typography>
                        </button>
                      </li>
                      {sitesQuery.data.map((site) => (
                        <li key={site.siteId}>
                          <button
                            data-selected={filterControls.isSelected(
                              site.siteId
                            )}
                            onClick={filterControls.toggleFilterFor(
                              site.siteId
                            )}
                          >
                            <Typography variant="eyebrow-m" whiteSpace="pre">
                              {site.siteName}
                            </Typography>
                          </button>
                        </li>
                      ))}
                    </>
                  )}
                </ul>
              </section>

              <section className={styles.weeklyCalendar}>
                {week && siteIds && (
                  <WeeklyCalendarSection
                    week={week}
                    renderDay={(dayDate) => {
                      return (
                        <Day
                          key={dayDate.toISODate()}
                          now={now}
                          selected={calendarControls.isDaySelected(dayDate)}
                          isToday={calendarControls.isToday(dayDate)}
                          onClick={calendarControls.onDaySelect}
                          date={dayDate}
                          siteIds={siteIds}
                          bookableUntil={bookableUntil}
                        />
                      );
                    }}
                  />
                )}
              </section>

              <section className={styles.body}>
                {month?.isValid && siteIds && (
                  <div className={styles.bookingSection}>
                    <div
                      className={styles.monthCalendar}
                      data-collapse-for-mobile={!!day || forceMobile}
                    >
                      <header className={styles.navHeader}>
                        <div className={styles.monthHeader}>
                          {range(-1, 1, (offset) => {
                            const date = month.plus({ month: offset });
                            return (
                              <div
                                key={date.toISODate()}
                                className={styles.monthName}
                                style={{
                                  transform: `translateX(${100 * offset}%)`,
                                  opacity: offset === 0 ? 1 : 0,
                                }}
                              >
                                <MonthName date={date} />
                              </div>
                            );
                          })}
                        </div>
                        <nav>
                          <IconButton
                            disabled={!calendarControls.canNavBack()}
                            onClick={calendarControls.navigateBy(-1)}
                            icon={
                              <div className={styles.chevronContainer}>
                                <ChevronLeftIcon
                                  data-disabled={!calendarControls.canNavBack()}
                                  className={styles.chevron}
                                />
                              </div>
                            }
                          />
                          <IconButton
                            disabled={!calendarControls.canNavForward()}
                            onClick={calendarControls.navigateBy(1)}
                            icon={
                              <div className={styles.chevronContainer}>
                                <ChevronRightIcon
                                  data-disabled={
                                    !calendarControls.canNavForward()
                                  }
                                  className={styles.chevron}
                                />
                              </div>
                            }
                          />
                        </nav>
                      </header>
                      <section className={styles.calendars}>
                        {range(-1, 1, (offset) => {
                          const date = month.plus({ month: offset });
                          return (
                            <div
                              key={date.toISODate()}
                              className={styles.calendar}
                              style={{
                                transform: `translateX(${100 * offset}%)`,
                                opacity: offset === 0 ? 1 : 0,
                              }}
                            >
                              <MiniCalendar
                                date={date}
                                renderDay={(dayDate) => {
                                  return (
                                    <Day
                                      now={now}
                                      selected={calendarControls.isDaySelected(
                                        dayDate
                                      )}
                                      isToday={calendarControls.isToday(
                                        dayDate
                                      )}
                                      onClick={calendarControls.onDaySelect}
                                      date={dayDate}
                                      siteIds={siteIds}
                                      bookableUntil={bookableUntil}
                                    />
                                  );
                                }}
                              />
                            </div>
                          );
                        })}
                      </section>
                    </div>
                    <section
                      className={styles.dayCalendar}
                      data-active={forceMobile || day != null}
                      key={`${day?.toISODate()}${siteIds.join("")}`}
                    >
                      {day && (
                        <>
                          <time className={styles.time}>
                            <Typography variant="eyebrow-m" color="subtle">
                              <FullMonthDay date={day} />
                            </Typography>
                          </time>
                          <div className={styles.dayTimes}>
                            <ListView
                              key={siteSlotsQuery.dataUpdatedAt}
                              onSiteResetClick={dailyListControls.onSitesReset}
                              allSitesSelected={
                                dailyListControls.allSitesSelected
                              }
                              onSelect={dailyListControls.onSlotSelect}
                              selectedSlotId={slotId}
                              slots={siteSlotsQuery.data}
                            />
                          </div>
                        </>
                      )}
                    </section>
                  </div>
                )}
              </section>
            </div>

            <Expandable isOpen={slotId != null && siteSlotsQuery.data != null}>
              <section className={styles.cta}>
                <div className={styles.ctaContent}>
                  <Sticky delay={300}>
                    {slotId != null && (
                      <BookingActions
                        slotId={slotId}
                        onBook={dataControls.displayOverlay}
                        onError={dataControls.hideOverlay}
                        onSlotIsTaken={dataControls.refetchDaySlots}
                      />
                    )}
                  </Sticky>
                </div>
              </section>
            </Expandable>
          </div>
        </div>
      </div>
    </FullScreenPageLayout>
  );
}
