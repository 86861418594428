import { IPublicClientApplication } from "@azure/msal-browser";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { RouterContext } from "@pomle/react-router-paths";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Config } from "auth/config/getConfig";
import { APIContext } from "render/context/APIContext";
import { AppInsightsContext } from "render/context/AppInsightsContext";
import { ConfigContext } from "render/context/ConfigContext";
import { ErrorBoundaryContext } from "render/context/ErrorBoundaryContext";
import {
  AuthSessionLanguageSetter,
  InternationalizationContext,
} from "render/context/InternationalizationContext";
import { Authenticated, MSALContext } from "render/context/MSALContext";
import { PopupContext } from "render/context/PopupContext";
import { QueryContext } from "render/context/QueryContext";
import { SnackbarContext } from "render/context/SnackbarContext";
import { TrackingContext } from "render/context/TrackingContext";
import { Router } from "render/routes/Router";
import { LoginErrorView } from "render/views/LoginError/LoginErrorView";
import "./App.sass";
import { ApplicationLayout } from "./components/ApplicationLayout";

export function App({
  config,
  insights,
  msal,
}: {
  msal: IPublicClientApplication;
  config: Config;
  insights: ApplicationInsights;
}) {
  return (
    <ConfigContext config={config}>
      <AppInsightsContext appInsights={insights}>
        <ErrorBoundaryContext>
          <RouterContext history={window.history}>
            <TrackingContext>
              <MSALContext msal={msal}>
                <APIContext>
                  <QueryContext>
                    <ReactQueryDevtools />
                    <InternationalizationContext>
                      <Authenticated ErrorView={LoginErrorView}>
                        <AuthSessionLanguageSetter>
                          <SnackbarContext>
                            <PopupContext>
                              <ApplicationLayout>
                                <Router />
                              </ApplicationLayout>
                            </PopupContext>
                          </SnackbarContext>
                        </AuthSessionLanguageSetter>
                      </Authenticated>
                    </InternationalizationContext>
                  </QueryContext>
                </APIContext>
              </MSALContext>
            </TrackingContext>
          </RouterContext>
        </ErrorBoundaryContext>
      </AppInsightsContext>
    </ConfigContext>
  );
}
